import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import { OutboundLink } from "gatsby-plugin-google-gtag"

type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout title="byjos.dev" location={location}>
    <Seo title="About me" />
    <div>
      <p>
        Hey there! I'm Lukasz, and I'm currently building{" "}
        <OutboundLink href="https://DailyLama.com">
          DailyLama.com
        </OutboundLink>
        . It's a new startup I'm pretty excited about - more details coming soon!
      </p>
      <p>
        When I'm not working on DailyLama, I'm all about cloud infrastructure and DevOps. 
        I love tinkering with serverless solutions and making complex systems work smoothly. 
        It's like solving a giant puzzle, but with code!
      </p>
      <p>
        I've got some fancy certifications as a{" "}
        <OutboundLink href="https://www.credential.net/93358416-e42e-4974-9c8e-5a91c5fa033d">
          GCP Developer
        </OutboundLink>{" "}
        and{" "}
        <OutboundLink href="https://www.credential.net/b7e20cd0-4403-4768-bec5-e99b0d5e51aa">
          Architect
        </OutboundLink>
        , but what really gets me going is building scalable solutions that make life easier for developers and users alike.
      </p>
      <p>
        In my past life, I helped launch Google Assistant in Poland. It was a wild ride, 
        juggling product quality, tech partnerships, and even dipping my toes into marketing campaigns.
      </p>
      <p>
        Oh, and I've dabbled in Android and Flutter app development too. I enjoy the challenge of 
        managing the entire launch process from scratch - it keeps things interesting!
      </p>
      <p>
        Want to know more? Check out my <a href={"/cv/cv_public_lukasz_byjos.pdf"} title="Lukasz Byjoś CV">CV</a> for the nitty-gritty details.
      </p>
    </div>
    <div className="bio-footer">
      <Bio />
    </div>
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
